import React, { useState } from 'react'
import GoTrue from 'gotrue-js'

import {
  Button,
  Box,
  CircularProgress,
  Backdrop,
  Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { sendToLogin } from '../../utils/auth'

const auth = new GoTrue({
  APIUrl: 'https://rise.trinet.com/.netlify/identity',
  setCookie: true,
})

const LogoutPage = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ isError: false })

  const handleLogout = () => {
    const user = auth.currentUser()

    if (user) {
      setLoading(true)
      user
        .logout()
        .then(() => {
          sendToLogin()
        })
        .catch((err) => {
          setError({
            isError: true,
            message:
              err.json && err.json.error_description
                ? err.json.error_description
                : 'Logout error, please try again.',
          })
        })
    } else {
      sendToLogin()
    }
  }

  const handleClose = () => {
    setError({ isError: false })
  }

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Snackbar open={error.isError} onClose={handleClose}>
        <Alert variant="filled" severity="error">
          {error.message}
        </Alert>
      </Snackbar>
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
      <Box>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          width="100%"
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </Box>
    </Box>
  )
}

export default LogoutPage
