export function sendToLogin() {
  window.location.href = '/auth/login'
  window.history.pushState('login', 'Login', '/auth/login')
}

export function redirectToHome() {
  window.location.href = '/'
  window.history.pushState('TriNet Rise', 'TriNet Rise', '/')
}

export function uglyTempLogoutHack(user) {
  let listenerSet = false

  if (user) {
    const interval = setInterval(() => {
      const logoutBtn = document.querySelectorAll('[role="menuitem"]')
      const isLogoutBtn = (el) => (el.children.length >= 1
        ? el.children[0].textContent === 'Log Out'
        : false)

      if (logoutBtn[0] && isLogoutBtn(logoutBtn[0]) && !listenerSet) {
        listenerSet = true
        clearInterval(interval)
        logoutBtn[0].addEventListener('click', (e) => {
          e.preventDefault()
          user.logout()
          sendToLogin()
        })
        logoutBtn[0].addEventListener('DOMNodeRemovedFromDocument', () => {
          uglyTempLogoutHack(user)
        }, false)
      }
    }, 500)
  }
}
